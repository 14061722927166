import React from 'react';
import {isGranted} from "../../utils/authentication-util";
import {Popover} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import classNames from "classnames";

const DeleteButton = ({disabled, onClick, permission, className, callback, isVanilla = true}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    if (permission && !isGranted(permission)) return <></>;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (callback) {
            callback(Boolean(event.currentTarget));
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (callback) {
            callback(Boolean(null));
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {isVanilla &&
                <button aria-describedby={id} className={classNames('btn-icon-delete', className)} onClick={handleClick}
                        disabled={disabled}/>}

            {!isVanilla &&
                <DeleteIcon aria-describedby={id} className='btn-icon' onClick={handleClick} disabled={disabled}/>}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <div className="container-popover-delete">
                    <div className='col-12 form-heading'>
                        <h4>
                            Vous êtes sûr ?
                        </h4>
                    </div>
                    <div className="col-md-12">
                        <div className="container-buttons">
                            <button className='btn-ternary' onClick={onClick}>Oui, je suis sûr.</button>
                            <button className='btn-primary' onClick={handleClose}>Non</button>
                        </div>
                    </div>
                </div>
            </Popover>
        </>

    );
};

export default DeleteButton;