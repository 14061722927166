import React from 'react';
import {PERMISSION, SERVER_BASE_URL} from "../../config/constants";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {isGranted} from "../../utils/authentication-util";

const ExportButton = ({entityName, identifiers, isExportAll = true}) => {
    const {t} = useTranslation();

    const handleOnClick = async event => {
        event.preventDefault();

        const params = identifiers?.length > 0 ? `?ids=${identifiers.join("&ids=")}` : "";

        const response = await fetch(`${SERVER_BASE_URL}/api/services/app/${entityName}/GetExportedEntities${params}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                // eslint-disable-next-line no-undef
                'Authorization': `Bearer ${abp.auth.getToken()}`
            }
        });

        const blob = await response.blob();

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Export_${entityName}_${dayjs().format("YYYY/MM/DD")}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    if (!isGranted(PERMISSION.EXPORT_DATA)) return <></>;

    if (!isExportAll) {
        return (<button className='btn-secondary btn-min-w' onClick={handleOnClick} disabled={identifiers?.length <= 0}>
            {t(`common.export_${entityName.toLowerCase()}_button`, identifiers?.length ?? 0)}
        </button>)
    }


    return (<button className='btn-secondary btn-min-w' onClick={handleOnClick}>
        {t(`common.export_all_${entityName.toLowerCase()}_button`)}
    </button>);
};

export default ExportButton;