import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'taskManagement',
    initialState: {modalIsOpen: false, selectedTaskId: "0", selectedProjectId: undefined},
    reducers: {
        setModalIsOpen: (state, {payload}) => {
            state.modalIsOpen = payload
        },
        setSelectedTaskId: (state, {payload}) => {
            state.selectedTaskId = payload
        },
        setSelectedProjectId: (state, {payload}) => {
            state.selectedProjectId = payload
        }
    }
});

export default slice.reducer;

export const {setModalIsOpen, setSelectedTaskId, setSelectedProjectId} = slice.actions;

export const selectModalIsOpen = state => state.taskManagement.modalIsOpen;

export const selectSelectedTaskId = state => state.taskManagement.selectedTaskId;
export const selectSelectedProjectId = state => state.taskManagement.selectedProjectId;