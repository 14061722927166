import React, {useEffect} from 'react';
import Modal from "react-modal";
import SelectWithLabel from "../interactive/SelectWithLabel";
import {useCreateContactMutation} from "../../api/ContactApi";
import useFormValidation from "../../hooks/useFormValidation";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import InputWithLabel from "../interactive/InputWithLabel";
import {CONTACT_TITLE_OPTIONS, CONTACT_TYPE_OPTIONS, DEFAULT_CONTACT} from "../../config/constants";
import {setSelectedProjectId} from "./contactSlice";
import {useDispatch} from "react-redux";

const CreateContactModal = ({isOpen, onRequestClose, projectId}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [createContact, {isLoading: isCreating}] = useCreateContactMutation();

    useEffect(() => {
        return () => {
            dispatch(setSelectedProjectId(undefined));
        };
    }, []);

    useEffect(() => {
        if (!isOpen) setValues({...DEFAULT_CONTACT});
        else if (!!projectId) setValues({...DEFAULT_CONTACT, projectId: parseInt(projectId)});
    }, [isOpen]);

    const handleSubmit = async values => {
        try {
            await createContact(values).unwrap();
            setValues(DEFAULT_CONTACT);
            dispatch(setSelectedProjectId(undefined));
            onRequestClose();
        } catch (error) {
            console.error(error);
            toast.error(t('contact.form.error.contact_not_created'));
        }
    };

    const {
        values, handleOnChange, handleOnSelectChange, handleOnSubmit, setValues
    } = useFormValidation({...DEFAULT_CONTACT, projectId}, () => ({}), handleSubmit);

    return (
        <Modal
            isOpen={isOpen} className="modal modal-task" overlayClassName="modal-overlay"
            contentLabel="Contact Modal" onRequestClose={onRequestClose}>
            <div className="container">
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <div className='container-shadow form row'>
                            <div className='col-12 form-heading'>
                                <h3>
                                    Créer un contact
                                </h3>
                                <button className='btn-close' onClick={onRequestClose}/>
                            </div>

                            <SelectWithLabel label="Titre" options={CONTACT_TITLE_OPTIONS}
                                             placeholder="Veuillez choisir le titre du contact..."
                                             values={values} name="title"
                                             onChange={handleOnSelectChange} tabIndex="1"/>

                            <SelectWithLabel label="Profession" options={CONTACT_TYPE_OPTIONS}
                                             placeholder="Veuillez choisir la profession du contact..."
                                             values={values} name="type"
                                             onChange={handleOnSelectChange} tabIndex="2"/>

                            <InputWithLabel label="Prénom" id="firstName" values={values} tabIndex="3"
                                            onChange={handleOnChange}
                                            placeholder="Veuillez entrer un prénom..."/>

                            <InputWithLabel label="Nom" id="name" values={values} tabIndex="4"
                                            onChange={handleOnChange}
                                            placeholder="Veuillez entrer un nom..."/>

                            <InputWithLabel label="Nom d'entreprise" id="enterpriseName" values={values} tabIndex="5"
                                            onChange={handleOnChange}
                                            placeholder="Veuillez entrer un nom d'entreprise..."/>

                            <InputWithLabel label="Numéro de TVA d'entreprise" id="tvaNumber" values={values}
                                            tabIndex="6"
                                            onChange={handleOnChange}
                                            placeholder="Veuillez entrer un numéro de TVA d'entreprise..."/>

                            <InputWithLabel label="Numéro de gsm" id="mobileNumber" values={values} tabIndex="7"
                                            onChange={handleOnChange}
                                            placeholder="Veuillez entrer un numéro de gsm..." type="tel"/>

                            <InputWithLabel label="Email" id="email" values={values} tabIndex="8"
                                            onChange={handleOnChange}
                                            placeholder="Veuillez entrer un email..." type="email"/>

                            <div className='col-12 btn-group text-center'>
                                <button className='btn-ternary' onClick={handleOnSubmit} disabled={isCreating}>
                                    enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateContactModal;