import {api} from "../app/SynappApi";
import {TAGS} from "../config/constants";
import {getProvidedTags, objectToQueryStrings} from "../utils/query-utils";

const eventApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query({
            query: params => `/api/services/app/Event/GetAll${objectToQueryStrings(params)}`,
            transformResponse: (response) => response.result,
            providesTags: result => getProvidedTags(result.items, TAGS.EVENTS)
        }),
        getEvent: builder.query({
            query: (id) => `api/services/app/Event/Get?id=${id}`,
            transformResponse: (response) => response.result,
            providesTags: (result, error, id) => [{type: TAGS.EVENTS, id}],
        }),
        createEvent: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Event/Create',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: TAGS.EVENTS, id: TAGS.DEFAULT}],
        }),
        updateEvent: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Event/Update',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.EVENTS, id}],
        }),
        deleteEvent: builder.mutation({
            query(id) {
                return {
                    url: `api/services/app/Event/Delete?id=${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{type: TAGS.EVENTS, id}]
        }),
        updateEventStatus: builder.mutation({
            query: (body) => ({
                url: 'api/services/app/Event/UpdateStatus',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, {id}) => [{type: TAGS.EVENTS, id}]
        })
    })
});

export const {
    useGetEventsQuery,
    useGetEventQuery,
    useCreateEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
    useUpdateEventStatusMutation
} = eventApi;